import React from "react";
import Service from "templates/service";
import Heading from "components/core/Typography/Heading";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";

export interface TermsOfUseProps {
  location: Location;
}

export default ({ location }: TermsOfUseProps) => {
  const { t } = useTranslation();

  return (
    <Service
      title={t("pages.terms.title")}
      subtitle={t("pages.terms.subtitle")}
      seoProps={{
        title: t("pages.terms.seo.title"),
        description: t("pages.terms.seo.description"),
        image: {
          relativePath: "meta/customerly-live-chat-terms-of-use.jpg",
          alt: "Customerly Terms of Use",
        },
        structuredData: [
          {
            "@context": "https://schema.org",
            "@type": "Corporation",
            brand: "Customerly",
            name: "Live Chat Software",
            sameAs: [
              "https://www.facebook.com/customerly",
              "https://www.instagram.com/customerly/",
              "https://www.linkedin.com/company/customerly",
              "https://www.pinterest.ie/customerly/",
              "https://twitter.com/customerlyio",
              "https://www.youtube.com/c/customerly",
            ],
            url: "https://www.customerly.io/",
          },
        ],
      }}
      location={location}
    >
      <Heading level={1}>The Agreement</Heading>
      <p>
        The Agreement set forth here below (hereinafter referred to as the
        “Agreement”) is entered into by and between Customerly Ltd (hereinafter
        referred to as “Customerly”) and the person, the entity, or the person
        on behalf of an entity/company or another person, accessing, using or
        expressly accepting Services provided by Customerly (hereinafter
        referred to as the “Client”).
      </p>
      <p>
        Through this Agreement the Client can purchase online software, services
        and any other products from Customerly by the subscriptions of order
        forms, online registration, order confirmations and any other procedure
        proposed by Customerly on its website, by the dispatch of e-mail,
        referring to this Agreement. The Agreement sets forth terms and
        conditions under which products and services will be delivered and it
        governs subsequent Client’s purchases as well.
      </p>
      <p>
        The Agreement Effective Date below (hereinafter referred to as
        “Effective Date”) is the date of the first Client’s access to any
        Services (as listed below) through any online provisioning, registration
        or order procedure.
      </p>
      <p>
        Within the meaning and for the purposes of this Agreement Customerly
        means Customerly Ltd, an Irish Corporation with offices at Ground Floor,
        71 Lower Baggot Street, Dublin, D02 P593, Ireland
      </p>
      <p>
        Client's billing address indicated on the applicable Order Form, can be
        located anywhere in the world.
      </p>
      <p>
        Agreement Modifications: Customerly could periodically modify this
        Agreement by notifying the Client of the changes that will become
        effective upon renewal of Client’s subscription term or upon the entry
        into a new order form. Customerly will use Client's account, email or
        other reasonable means to notify any changes to the Client. Client may
        be required to click to accept or agree to the modifications. The new
        Agreement Effective Date will be the date of the Client’s explicit
        acceptance or the continued use of the Services considering that as a
        silent acceptance after the publication of the updated version of the
        Agreement.
      </p>
      <p>
        In case the notified changes will take effect before the next renewal.
        the Client may object to the changes and may terminate the applicable
        terms and use the applicable service until the expiration of the
        previous terms.
      </p>
      <p>
        The explicit or evident and silent acceptance of the Agreement as well
        as the access or use of any Customerly’s Services means to the Parties
        to be bound by all terms, conditions and notices indicated in this
        Agreement.
      </p>
      <p>
        If the Client does not agree to all terms, conditions and notices of
        this Agreements needs to avoid any use of any services provided by
        Customerly.
      </p>
      <p>
        Customerly and the Client legally binding upon this Agreement expressly
        agree about a mandatory arbitration provision that provide the use of
        Arbitration to resolve disputes, rather than jury trials.
      </p>
      <Heading level={2}>Definitions</Heading>
      <p>
        "Affiliate" means any entity or person which is or will be under the
        Client’s control where "control" means ownership of or the right to
        address and control Affiliate’s decision and action.
      </p>
      <p>
        "AUP" means Customerly's Acceptable Use Policy,
        <Link to="/cookies/"> available here </Link> or a successor URL.
      </p>
      <p>
        "Contractor" means an independent contractor or consultant who is not a
        competitor of Customerly.
      </p>
      <p>
        "Client Data" means any data of any type that is submitted to the
        Services by or on behalf of the Client, including without limitation:
        (a) data submitted, uploaded or imported to the Services by the Client
        (including from Third Party Platforms) and (b) data provided by or about
        People (including chat and message logs) that are collected from the
        Client using the Services.
      </p>
      <p>
        "Client Properties" means Client's websites, apps, or any other tools
        and instruments owned and managed by (or for the benefit of) Client
        through which Client uses the Services for communication purposes with
        People.
      </p>
      <p>
        "Dashboard" means Customerly’s user interface for accessing and
        administering the Services that Client may access via web or via
        Customerly Apps.
      </p>
      <p>
        "Documentation" means the user documentation and instructions provided
        with the Services.
      </p>
      <p>
        "Feedback" means comments, questions, suggestions or other feedback
        coming from Client relating to any Customerly product or service, used
        or suggested.
      </p>
      <p>
        "Customerly App" means one or more mobile applications included in the
        applicable Service that is or will be made available by Customerly.
      </p>
      <p>
        "Customerly Integration Code" means the JavaScript code, software
        development kits or other code provided by Customerly for installations,
        placement and settings on Client Properties.
      </p>
      <p>
        "Laws" means all applicable local, state, european and international
        laws, regulations and conventions including, without limitation, related
        to data privacy and data transfer, international communications, and
        exportation of technical or personal data.
      </p>
      <p>
        "People" (and singular "Person") means Client’s end user or potential
        customers or any other people using or visiting Client Properties.
      </p>
      <p>
        "Permitted User" means any Client’s employee or Contractor or Affiliate
        who is or will be authorized to access the Services.
      </p>
      <p>
        "Sensitive Personal Information" means every information related to: (i)
        credit, debit or other payment card data subject; (ii) patient, medical
        or other protected health information; or (iii) any other personal data
        of an EU citizen deemed to be in a "special category" (as identified in
        EU Data Protection Directive 95/46/EC or any successor directive or
        regulation).
      </p>
      <p>
        "Services" means any software-as-a-service solution, full owned by
        Customerly, including the Dashboard, Customerly’s application
        programming interfaces (APIs), Customerly Integration Code and
        Customerly Apps:
        <ul>
          <li>
            <Link to="/solutions/customer-service/">
              Customer Service Software
            </Link>
          </li>
          <li>
            <Link to="/solutions/marketing-automation/">
              Marketing Automation Software
            </Link>
          </li>
          <li>
            <Link to="/solutions/customer-satisfaction/">
              Customer Satisfaction Software
            </Link>
          </li>
          <li>
            <Link to="/features/live-chat/">Live Chat Software</Link>
          </li>
          <li>
            <Link to="/features/live-chat-for-mobile-apps/">
              Live Chat for mobile apps
            </Link>
          </li>
          <li>
            <Link to="/features/live-chat-plugin-for-wordpress/">
              Live Chat for WordPress
            </Link>
          </li>
          <li>
            <Link to="/features/live-chat-plugin-for-shopify/">
              Live Chat for Shopify
            </Link>
          </li>
          <li>
            <Link to="/features/video-live-chat/">
              Video Live Chat Software
            </Link>
          </li>
          <li>
            <Link to="/features/help-center/">Help Center Software</Link>
          </li>
          <li>
            <Link to="/features/marketing-funnel/">
              Sales Marketing Software
            </Link>
          </li>
          <li>
            <Link to="/features/crm/">CRM Software</Link>
          </li>
          <li>
            <Link to="/features/email-marketing/">
              Email Marketing Software
            </Link>
          </li>
          <li>
            <Link to="/features/email-template-builder/">
              Email Template Builder
            </Link>
          </li>
          <li>
            <Link to="/features/audience-segmentation/">
              Audience Segmentation
            </Link>
          </li>
          <li>
            <Link to="/features/surveys/">In-app Survey Software</Link>
          </li>
          <li>
            <Link to="/features/net-promoter-score/">Net Promoter Score</Link>
          </li>
        </ul>
      </p>
      <p>
        "Taxes" means any domestic or foreign income, VAT, withholding, or
        similar taxes or tribute due in compliance with the Law because of the
        terms of this Agreement.
      </p>
      <p>
        "Third-Party Platform" means any software, service, data sources or
        products provided, used or employed by Client that are related or
        integrated with Services.
      </p>
      <Heading level={3}>Customerly Services</Heading>
      <p>
        Customerly provides a platform with a messaging software, a managing
        communications software, a dashboard for collecting, managing and
        accessing customers data, an import/export data system, a live chat, a
        survey system, a Net Promote Score system, an email marketing service
        and a series of services that allow the Client to manage independently
        any business relationships with customers. The Services include the
        necessary Code to enable chat and messaging tools.
      </p>
      <Heading level={4}>Services Provision and delivery</Heading>
      <p>
        Full package of services is provided on a term basis tacitly renewable.
      </p>
      <Heading level={4}>Services Access and Use</Heading>
      <p>
        Client and Permitted Users may access and use the Services exclusively
        for Client’s own benefit and in accordance with the terms and conditions
        of this Agreement, the Documentation (without limitation the number of
        People tracked). Use of and access to the Services is permitted only by
        Permitted Users. If Client is given passwords to access the Services on
        Customerly's systems, Client will require that all Permitted Users keep
        password information strictly confidential and not share such
        information with any unauthorized person. User IDs are granted to
        individual, named persons and may not be shared. If Client is accessing
        the Services using credentials provided by a third party, then Client
        will comply with all applicable terms and conditions of such third party
        regarding provisioning and use of such credentials. Client will be
        responsible for any and all actions taken using Client's accounts and
        passwords. If a Permitted User who has access to a user ID is no longer
        an employee (or Contractor, as set forth below) of the Client, then
        Client will immediately delete such user ID and otherwise terminate such
        Permitted User's access to the Service. The right to use the Services
        includes the right to deploy Customerly Integration Code on Client
        Properties in order to enable messaging, chat and similar functionality
        and to collect Client Data for use with the Services as further
        described below.
      </p>
      <Heading level={4}>Customerly Apps</Heading>
      <p>
        As Customerly shall provide one or more Apps for the use of Services
        purpose, they will be subjected to this Agreement terms and conditions.
        Customerly will allow Client to use the Apps internally by a limited,
        non-transferable, non-sublicensable, non-exclusive license, related to
        an applicable Subscription Term. This granting will be only in
        connection with Client's use of the Service and otherwise in accordance
        with the Documentation and this Agreement.
      </p>
      <Heading level={4}>
        Release and installation of Customerly Integration Code
      </Heading>
      <p>
        Established that Client must implement Customerly Integration Code on
        its Properties to enable features of the Services, according to all this
        Agreement terms and conditions, Customerly grants to the Client a
        limited, non-transferable, non-sublicensable, non-exclusive license
        during any applicable Subscription Term to duplicate the Customerly
        Integration Code in the form provided by Customerly on Client’s
        Properties exclusively to support Client's use of the Service and
        otherwise in accordance with the Documentation and this Agreement.
        Client will implement Customerly Integration Code in accordance with the
        Documentation and any other instructions provided by Customerly. Client
        acknowledges that any changes made to the Client Properties after
        initial implementation of Customerly Integration Code may cause the
        Services to cease working or function improperly and that Customerly
        will have no responsibility for the impact of any such Client changes.
      </p>
      <Heading level={4}>Contractors and Affiliates</Heading>
      <p>
        The Client may permit to Contractors, Affiliates and employees to serve
        as Permitted Users for the exclusive benefit of Client. The Client
        naturally will remain responsible for such individual’s compliance with
        all of the terms and conditions of this Agreement.
      </p>
      <Heading level={4}>General Restrictions</Heading>
      <p>
        Client will not (and will not permit any third party to): (a) rent,
        lease, provide access to or sublicense the Services to a third party;
        (b) use the Services to provide, or incorporate the Services into, any
        product or service provided to a third party; (c) reverse engineer,
        decompile, disassemble, or otherwise seek to obtain the source code or
        non-public APIs to the Services, except to the extent expressly
        permitted by applicable law (and then only upon advance notice to
        Customerly); (d) copy or modify the Services or any Documentation, or
        create any derivative work from any of the foregoing; (e) remove or
        obscure any proprietary or other notices contained in the Services
        (including without limitation (i) the "Powered by Customerly"
        designation that may appear as part of the deployment of the Services on
        Client Properties and (ii) notices on any reports or data printed from
        the Services); or (f) publicly disseminate information regarding the
        performance of the Services.
      </p>
      <Heading level={4}>Customerly APIs</Heading>
      <p>
        In the event that Customerly makes access to any APIs available as part
        of the Services, Customerly reserves the right to place limits on access
        to such APIs (e.g., limits on numbers of calls or requests). In
        addition, Customerly may monitor Client's use of such APIs and limit the
        number of possible Client’s calls or requests if there will be any doubt
        about a use in breach of this Agreement or a possible negative effect on
        the Services (or otherwise impose liability on Customerly).
      </p>
      <Heading level={4}>Initial free access</Heading>
      <p>
        Client will have free access as a trial or evaluation subscription to
        the Service (a "Trial Subscription"). During this period Client
        undertakes to use the Services in accordance with the terms and
        conditions of this Agreement for a period of thirty (14) days or such
        other period granted by Customerly (hereinafter referred to as the
        "Trial Period"). Trial Period is permitted exclusively for Client’s use
        to decide whether to purchase a paid subscription to the Services. Trial
        Subscriptions may not include all functionality and features accessible
        as part of a paid Subscription Term. If Client decides to not enter into
        a paid Subscription Term within the Trial Period, this Agreement and
        Client’s right to access and use the Services will terminate at the end
        of the Trial Period. Customerly has the right to terminate a Trial
        Subscription at any time for any reason.
      </p>
      <Heading level={3}> Client Data</Heading>
      <Heading level={5}> Rights in Client Data</Heading>
      <p>
        As between the parties, Client will retain all right, title and interest
        (including intellectual property rights) in and to the Client Data as
        provided to Customerly. Subject to the terms of this Agreement, the
        Client hereby grants to Customerly a non-exclusive, worldwide,
        royalty-free right to use, copy, store, transmit, modify, create
        derivative works of and display the Client Data exclusively to the
        extent necessary to provide the Services to Client and for internal
        analytical purposes.
      </p>
      <Heading level={5}> Storage of Client Data</Heading>
      <p>
        Customerly does not provide an archiving service and disclaim any
        obligations with respect to data storage. Customerly is committed to not
        intentionally delete any Client Data from any Service prior to
        termination of Client’s applicable Subscription Term. Customerly does
        not take any responsibility on any loss of data caused by a temporarily
        malfunction of the platform.
      </p>
      <Heading level={5}>Client undertakings and commitments</Heading>
      <p>
        Client is exclusively responsible for the accuracy, content and legality
        of all Client Data. Client represents and warrants to Customerly that
        he/she/it has all necessary rights, consents and permissions to collect,
        use and share data with other entities according to the Privacy Law
        currently in force and within the meaning and for the purposes of all
        parts of this Agreement (including rights in Client Data granted to
        Customerly).
      </p>
      <p>
        Client hereby declares that no used, shared or collected Data are in
        violation of Intellectual Property Rights or any other property or
        publicity Law.{" "}
      </p>
      <p>
        Client further represents and warrants that all Data complies with the
        AUP.
      </p>
      <p>
        Client specifically guarantees and promises not to use the Services to
        collect, store, process or transmit any Sensitive Personal Information.
        Consequently, according to this Agreement, Customerly will have no
        responsibility for Sensitive Personal Information and any related
        data-processing.{" "}
      </p>
      <Heading level={5}>Compliance with Laws</Heading>
      <p>
        Client agrees and undertakes to a full compliance with all applicable
        Laws concerning the purchase and the use of the Services. Given the
        above, Client will not engage in any activities in violation of existing
        legislation in force and coming into force, including anti-spam
        regulations and any other Consumer Protection Act.{" "}
      </p>
      <Heading level={5}>Client Properties’ Disclosure</Heading>
      <p>
        Client is fully aware that the Customerly Integration Code causes unique
        cookies ID to be associated with each Person who accesses the Client
        Properties. These cookies ID enable Customerly to provide the Services.
        Client is committed to include on each Client Property a link to its
        privacy policy that discloses Client's use of third party tracking
        technology to collect data about People as described in this Agreement.
        The Client's privacy policy will describe fully and exactly the method,
        the scopes and purposes of the data collection and processing done by
        means of Customerly Integration Code and that the data will be used
        and/or shared with Customerly as necessary part of the Services. Client
        is also committed to provide People with a clear and comprehensive
        information about storing and accessing of cookies or other information
        on the Peoples' devices where such activity occurs in connection with
        the Services and in according to applicable Laws. For clarity, as
        between Client and Customerly, Client will be the one in charge and
        accountable for obtaining the necessary clearances, consents and
        approvals from People under all applicable Laws.
      </p>
      <p>
        As regard as the Social Media Data, the Client and Customerly hereby
        agree about the following:
      </p>
      <ol type="a">
        <li>
          The Services use third-party services (e.g. Facebook, Zapier,
          Linkedin, Leadsbridge) to enable Client to retrieve publicly-available
          information about People including without limitation social media
          information, profile information, gender, company, job titles, photos,
          physical addresses, and website URLs (hereinafter referred to as
          “Social Media Data”) based on People's email addresses input into the
          Services.
        </li>
        <li>
          Restrictions. Client is committed to not use any Social Media Data
          obtained via the Services for the purposes of cookie tracking, ad
          exchanges, ad networks, data brokerages, sending electronic
          communications (including email) in violation of applicable Law, or
          any other activity or purpose that may be identified as prohibited by
          Customerly at any time.
        </li>
        <li>
          Disclosures. Client is in charge and agrees to obtain all necessary
          clearances, consents and approvals from People in order to use Social
          Media Data according to applicable Laws, including without limitation
          by disclosing in Client's privacy policy information about the
          collection and use of People's email addresses as set forth herein.
        </li>
      </ol>
      <p>
        Client will indemnify, defend and hold harmless Customerly from and
        against any and all claims, costs, damages, losses, liabilities and
        expenses (including reasonable attorneys' fees and costs) arising out of
        or in connection with any claim arising from or relating to any Client
        Data or breach or alleged breach by Customer of Section 3.3 (Customer
        Obligations). This indemnification obligation is subject to Client
        receiving (i) prompt written notice of such claim (but in any event
        notice in sufficient time for Client to respond without prejudice); (ii)
        the exclusive right to control and direct the investigation, defense, or
        settlement of such claim; and (iii) all necessary cooperation of
        Customerly at Client's expense. Given the above (a) Customerly may
        participate in the defense of any claim by counsel of its own choosing,
        at its cost and expense and (b) Client will not settle any claim without
        Customerly's prior written consent, unless the settlement fully and
        unconditionally releases Customerly and does not require Customerly to
        pay any amount, take any action, or admit any liability.
      </p>
      <Heading level={5}> Aggregated Anonymous Data</Heading>
      <p>
        Notwithstanding anything to the contrary contained in this Agreement,
        Client agrees that Customerly may obtain and aggregate technical and
        other data about Client's use of the Services that is non-personally
        identifiable with respect to Client ("Aggregated Anonymous Data"), and
        Customerly may use the Aggregated Anonymous Data to analyse, improve,
        support and operate the Services and otherwise for any business purpose
        during and after the term of this Agreement, including without
        limitation to generate industry benchmark or best practice guidance,
        recommendations or similar reports for distribution to and consumption
        by Client and other Customerly customers. For clarity, this provision
        shall not give Customerly entitlement to identify Client as the source
        of any Aggregated Anonymous Data.
      </p>
      <Heading level={3}>Security</Heading>
      <p>
        Customerly undertakes to use reasonable technical and organisational
        measures available on the market and specifically developed to prevent
        unauthorized access, use, alteration or disclosure of any Service or
        Client Data. Nevertheless, Customerly will have no responsibility for
        errors in transmission, unauthorised third-party access or other causes
        beyond Customerly's control.
      </p>
      <Heading level={3}> Third-Party Platforms</Heading>
      <p>
        The Services may support integrations with Third-Party Platforms, with
        whom the Services need to connect and communicate. Consequently, Client
        may be required to input credentials in order for the Services to access
        and receive relevant information from such Third-Party Platforms. By
        enabling use of the Services with any Third-Party Platform, Client
        authorizes Customerly to access his/her/its accounts with such
        Third-Party Platform, for the purposes indicated in this Agreement.
        Client is exclusively responsible for the full compliance with any
        relevant terms and conditions of the Third-Party Platforms and he/she/it
        is committed to maintain appropriate accounts in good standing with the
        providers of the Third-Party Platforms. Client acknowledges and agrees
        that Customerly has no responsibility or liability for any Third-Party
        Platform or any Client Data exported to a Third-Party Platform.
        Customerly does not guarantee that the Services will maintain
        integrations with any Third-Party Platform and Customerly may disable
        integrations of the Services with any Third-Party Platform at any time
        with or without notice to Client. For clarity, this Agreement governs
        Client’s use of and access to the Services, even if accessed through an
        integration with a Third-Party Platform.
      </p>
      <Heading level={3}> Ownership</Heading>
      <Heading level={5}>Customerly Technology</Heading>
      <p>
        This is a subscription agreement concerning the access to and the use of
        the Services. Client is aware that it is obtaining only a limited right
        to the Services and that irrespective of any use of the words
        "purchase", "sale" or like terms in this Agreement no ownership rights
        are being conveyed to Client under this Agreement. Client agrees that
        Customerly or its suppliers retain all right, title and interest
        (including all patent, copyright, trademark, trade secret and other
        intellectual property rights) in and to the Services and all
        Documentation, professional services deliverables and any and all
        related and underlying technology and documentation and any derivative
        works, modifications or improvements of any of the foregoing, including
        as may incorporate Feedback (called on the whole "Customerly
        Technology"). Except as expressly set forth in this Agreement, no rights
        in any Customerly Technology are granted to Client. Further, Client is
        aware that the Services are offered as an on-line, hosted solution, and
        that he/she/it has no right to obtain a copy of any of the Services,
        except for Customerly Integration Code and the Customerly Apps in the
        format provided by Customerly.
      </p>
      <Heading level={5}> Feedback</Heading>
      <p>
        Client may sometimes submit Feedback to Customerly, who may freely use
        or exploit Feedback in connection with any of its products or services.
      </p>
      <Heading level={3}> Subscription Term, Fees & Payment</Heading>
      <Heading level={5}>Subscription Term and Renewals</Heading>
      <p>
        Each Subscription Term will automatically renew for an additional period
        of twelve months, unless either party gives the other written notice of
        termination at least thirty (30) days prior to expiration of the
        then-current Subscription Term.
      </p>
      <Heading level={5}>Fees and Payment</Heading>
      <p>
        All fees will be paid monthly by Client via Credit Card. Except as
        expressly established in Section 9 (Limited Warranty) and Section 14
        (Indemnification), all fees are non-refundable. The rates are valid for
        the initial twelve (12) months of each Subscription Term and thereafter
        may be subject to an automatic adjustment increase of up to ten percent
        (10%) per year. Client is aware of his/her/its responsibility for paying
        all related Taxes. In case Law requires Client to withhold any Taxes
        from Client's payment, the fees payable by Client will be increased as
        necessary so that after making any required withholdings, Customerly
        receives and retains (free from any liability for payment of Taxes) an
        amount equal to the amount it would have received had no such
        withholdings been made. Any late payments will be subject to a service
        charge equal to 1.5% per month of the amount due or the maximum amount
        allowed by law, whichever is less.
      </p>
      <Heading level={5}>Payment Via Credit Card</Heading>
      <p>
        Given that Client will pay for Services via credit card, debit card or
        other payment card ("Credit Card"), the following terms will be
        applicable:
      </p>
      <Heading level={5}>Recurring Billing Authorization</Heading>
      <p>
        By providing Credit Card information and agreeing to purchase any
        Services, Client hereby authorizes Customerly (or its designee) to
        automatically charge Client's Credit Card on the same date of each
        calendar month (or the closest prior date, if there are fewer days in a
        particular month) during the Subscription Term for all fees accrued as
        of that date (if any). Client is aware and agrees that the amount billed
        and charged each month may vary depending on Client's use of the
        Services and may include subscription fees for the remainder of Client's
        applicable billing period and overage fees for the prior month. Foreign
        Transaction Fees. Client acknowledges that for certain Credit Cards, the
        issuer of Client's Credit Card may charge a foreign transaction fee or
        other charges.
      </p>
      <Heading level={5}>Invalid Payment</Heading>
      <p>
        If a payment is not successfully settled due to expiration of a Credit
        Card, insufficient funds, or otherwise, Client remains responsible for
        any amounts not remitted to Customerly and Customerly may, in its sole
        discretion, either (i) invoice Client directly for the deficient amount,
        (ii) continue billing the Credit Card once it has been updated by Client
        (if applicable) or (iii) terminate this Agreement.
      </p>
      <p>
        Changing Credit Card Information. At any time, Client may change its
        Credit Card information by entering updated Credit Card information via
        the "Billing" page on the “Settings” section in the Dashboard.
      </p>
      <Heading level={5}>Termination of Recurring Billing</Heading>
      <p>
        In addition to any termination rights set forth in this Agreement,
        Client may terminate the Subscription Term by sending Customerly notice
        of non-renewal to info@Customerly.io in accordance with Section 7.1
        (Subscription Term and Renewals) or, if Client's Subscription Term is on
        a monthly basis (or if otherwise permitted by Customerly), by
        terminating via the "Billing" page on the “Settings” section in the
        Dashboard, with termination effective at the end of the current
        Subscription Term. As set forth in Section 2.9 (Trial Subscriptions), if
        Client does not enter into a paid Subscription Term following a Trial
        Period, this Agreement and Client's right to access and use the Services
        will terminate at the end of the Trial Period and Client's Credit Card
        will not be charged.
      </p>
      <Heading level={5}>Payment of Outstanding Fees</Heading>
      <p>
        As soon as the Subscription Term will be terminated or expired
        Customerly will charge Client's Credit Card (or invoice Client directly)
        for any outstanding fees for Client's use of the Services during the
        Subscription Term. No additional fees will be charged after such term.
      </p>
      <Heading level={5}>Suspension of Service</Heading>
      <p>
        If Client's account would be thirty (30) days or more past the deadline,
        in addition to any of its other rights or remedies (including but not
        limited to any termination rights hereby established), Customerly has
        without liability the right to suspend Client's access to the applicable
        and to any other Service, until the amounts due are fully paid.
        Customerly also reserves the right to suspend Client's access to the
        Services without liability to Client if the Services are used is in
        violation of the Acceptable Use Policy (AUP).
      </p>
      <Heading level={3}> Term and Termination </Heading>
      <Heading level={5}> Term</Heading>
      <p>
        This Agreement is effective as of the Effective Date and expires on the
        date of expiration or termination of all Subscription Terms.
      </p>
      <Heading level={5}> Termination for Cause</Heading>
      <p>
        Either party may terminate this Agreement (including all related Order
        Forms) if the other party (a) fails to cure any material breach of this
        Agreement (including a failure to pay fees) within thirty (30) days
        after written notice; (b) ceases operation without a successor; or (c)
        seeks protection under any bankruptcy, receivership, trust deed,
        creditors' arrangement, composition, or comparable proceeding, or if any
        such proceeding is instituted against that party (and not dismissed
        within sixty (60) days thereafter).
      </p>
      <Heading level={5}> Effect of Termination</Heading>
      <p>
        As soon as this Agreement will be terminated or expired, for any cause,
        Client will immediately cease any and all use of and access to all
        Services (including any and all related Customerly Technology) and
        delete or return upon Customerly's request, any and all copies of the
        Documentation, any Customerly passwords or access codes and any other
        Customerly Confidential Information in its possession. Provided that the
        termination of this Agreement was not due to Client's breach, Client may
        retain and use internally copies of all reports exported from any
        Service prior to termination. Client is fully aware that after the
        termination it will have no further access to any Client Data input into
        any Service, and that Customerly may delete any such data as may have
        been stored by Customerly at any time. Except where an exclusive remedy
        is specified, the exercise of either party of any remedy under this
        Agreement, including termination, will be without prejudice to any other
        remedies it may have under this Agreement, by law or otherwise.
      </p>
      <Heading level={5}>Survival</Heading>
      <p>
        Although any expiration or termination of this Agreement the following
        Sections will remain into force: 2.7 (General Restrictions), 2.9 (Trial
        Subscriptions), 3.2 (Storage of Customer Data), 3.4 (Indemnification by
        Customer), 3.5 (Aggregated Anonymous Data), 6 (Ownership), 7.2 (Fees and
        Payment), 7.3 (Payment Via Credit Card), 8 (Term and Termination), 9.2
        (Warranty Disclaimer), 13 (Limitation of Remedies and Damages), 14
        (Indemnification), 15 (Confidential Information) and 17 (General Terms).
      </p>
      <Heading level={3}> Limited Warranty</Heading>
      <Heading level={5}>Limited Warranty</Heading>{" "}
      <p>
        Customerly guarantees, for Client's benefit only, that each Service will
        operate in substantial conformity with the applicable Documentation.
        Customerly's exclusive liability (and Client's sole and exclusive
        remedy) for any breach of this warranty will be, at no charge to Client,
        for Customerly to use commercially reasonable efforts to correct the
        reported non-conformity, or if Customerly determines such remedy to be
        impracticable, either party may terminate the applicable Subscription
        Term and Client will receive a refund of any fees Customer has pre-paid
        for use of such Service as a remedy for the portion of the applicable
        Subscription Term. The limited guarantee established in this Section 9.1
        will not apply in the following events: (i) Client’s claim within thirty
        (30) days of the date of first non-conformity spot, (ii) misuse,
        unauthorized modifications or third-party hardware, software or services
        causing the error, or (iii) to use provided on a no-charge, trial or
        evaluation basis.
      </p>
      <Heading level={5}> Warranty Disclaimer</Heading>
      <p>
        Except for the limited warranty provided in Section 9.1, all Services
        and Professional Services are provided “as is”. Customerly or its
        suppliers will not make any other warranties express or implied,
        including but limited to warranties of merchantability, title, fitness
        for a purpose or non-infringement. Customerly does not guarantee that
        Client's use of Services will be uninterrupted or error-free, nor does
        Client warrant that it will review the Customer Data for accuracy or
        that it will preserve or maintain the Client Data without loss or
        corruption. Customerly shall not be liable for the results of any
        communications sent or any failure of communications sent using the
        Services. Customerly shall not be liable for delays, interruptions,
        service failures or any other problems related to the use of Internet,
        e-communications, Third-Party Platforms or other systems outside the
        control of Customerly. Client may have other statutory rights, but the
        duration of statutorily required warranties, if any, shall be limited to
        the shortest period permitted by Law in force at that time.{" "}
      </p>
      <Heading level={3}> Availability and Service Credits</Heading>
      <p>
        The Services are available subject to Customerly's Service Level
        Agreement. ("SLA").
      </p>
      <Heading level={3}> Support</Heading>
      <p>
        During the Subscription Term of each Service, Customerly will provide
        end user support in accordance with Customerly's Support Policy
        ("Support Policy").
      </p>
      <Heading level={3}> Professional Services </Heading>
      <p>
        Customerly will provide the professional consulting services
        ("Professional Services") purchased in the applicable Order Form. The
        scope of Professional Services will be as established in a Statement of
        Work referencing this Agreement and executed by both parties describing
        the work to be performed, the fees and any applicable milestones,
        dependencies and other technical specifications or related information
        ("SOW"). Unless Professional Services are provided on a fixed-fee basis,
        Client will pay Customerly for that at the per-hour rates established in
        the Order Form (or, if not specified, at Customerly’s standard rates)
        for any excess services. Client will reimburse Customerly for reasonable
        travel and lodging expenses as incurred. Client may use anything
        delivered as part of the Professional Services in support of authorized
        use of the Services and subject to the terms regarding Client's rights
        to use the Service set forth in Section 2 (Customerly Services) and the
        applicable SOW, but Customerly will retain all right, title and interest
        in and to any such work product, code or deliverables and any
        derivative, enhancement or modification thereof created by Customerly
        (or its agents).
      </p>
      <Heading level={3}> Limitation of Remedies and Damages</Heading>
      <Heading level={5}>Consequential Damages Waiver</Heading>
      <p>
        Except for excluded claims (see the list here below), neither Party of
        this Agreement, nor any Suppliers of them, shall have any liability
        arising out of this Agreement or related to it, for any loss of use,
        lost data, lost profits, security failures, business interruption or any
        other indirect, special, incidental, reliance or consequential damages
        of any kind, even if informed of the possibility of such damage in
        advance.{" "}
      </p>
      <Heading level={5}> Liability Cap</Heading>
      <p>
        Customerly’s and its suppliers’ liability to Client arising from this
        Agreement is limited and shall not exceed the amount paid by Client
        during the first twelve months period, starting from the first
        acceptance of this Agreement.{" "}
      </p>
      <Heading level={5}> Excluded Claims</Heading>
      <p>
        "Excluded Claims" means any claim arising (a) from Client's breach of
        Section 2.7 (General Restrictions); (b) under Section 3.3 (Client
        Obligations) or 3.4 (Indemnification by Client); or (c) from a party's
        breach of its obligations in Section 15 (Confidential Information) (but
        excluding claims arising from operation or non-operation of any
        Service).
      </p>
      <Heading level={5}>
        Nature of Claims and Failure of Essential Purpose
      </Heading>
      <p>
        Customerly and Client agree that the waivers and limitations specified
        in this Section 13 apply regardless of the form of action, whether in
        contact, tort (including negligence), strict liability or otherwise and
        will survive and apply even if any limited remedy specified in this
        Agreement is found to have failed of its essential purpose.
      </p>
      <Heading level={3}> Intellectual Property Infringement.</Heading>{" "}
      <p>
        Customerly will take an active part in defending Client from and against
        any claim, asserted as true by a third party, affirming that a Service,
        when used as authorized under this Agreement, infringes a worldwide
        patent, copyright, or trademark.{" "}
      </p>
      <Heading level={3}> Confidential Information </Heading>
      <p>
        Each party (as "Receiving Party") agrees that all code, inventions,
        know-how, business, technical and financial information it obtains from
        the disclosing party ("Disclosing Party") constitute the confidential
        property of the Disclosing Party ("Confidential Information"), provided
        that it is identified as confidential at the time of disclosure or
        should be reasonably known by the Receiving Party to be confidential or
        proprietary due to the nature of the information disclosed and the
        circumstances surrounding the disclosure. Any Customerly Technology,
        performance information relating to any Service, and the terms and
        conditions of this Agreement will be deemed Confidential Information of
        Customerly without any marking or further designation. Except as
        expressly authorized herein, the Receiving Party will (1) hold in
        confidence and not disclose any Confidential Information to third
        parties and (2) not use Confidential Information for any purpose other
        than fulfilling its obligations and exercising its rights under this
        Agreement. The Receiving Party may disclose Confidential Information to
        its employees, agents, contractors and other representatives having a
        legitimate need to know (including, for Customerly, the subcontractors
        referenced in Section 17.8 (Subcontractors)), provided that such
        representatives are bound to confidentiality obligations no less
        protective of the Disclosing Party than this Section 15 and that the
        Receiving Party remains responsible for compliance by any such
        representative with the terms of this Section 15. The Receiving Party's
        confidentiality obligations will not apply to information that the
        Receiving Party can document: (i) was rightfully in its possession or
        known to it prior to receipt of the Confidential Information; (ii) is or
        has become public knowledge through no fault of the Receiving Party;
        (iii) is rightfully obtained by the Receiving Party from a third party
        without breach of any confidentiality obligation; or (iv) is
        independently developed by employees of the Receiving Party who had no
        access to such information. The Receiving Party may make disclosures to
        the extent required by law or court order, provided the Receiving Party
        notifies the Disclosing Party in advance and cooperates in any effort to
        obtain confidential treatment. The Receiving Party acknowledges that
        disclosure of Confidential Information would cause substantial harm for
        which damages alone would not be a sufficient remedy, and therefore that
        upon any such disclosure by the Receiving Party the Disclosing Party
        will be entitled to seek appropriate equitable relief in addition to
        whatever other remedies it might have at law.
      </p>
      <Heading level={3}> Co-Marketing</Heading>
      <p>
        Upon Customerly’s request, Client will issue with Customerly a joint
        press release ("Press Release") on a mutually agreed date. Both parties
        have the right to approve the text of the Press Release in advance, but
        such approval cannot be unreasonably delayed or withheld. Client also
        agrees to participate in other reasonable marketing activities that
        promote the benefits of the Services to other potential customers and
        allow Customerly to use its/his/her name and logo on Customerly's web
        site and in its promotional materials and documents. Client generally
        agrees that Customerly may disclose him/her as a customer of Customerly.
      </p>
      <Heading level={3}> General Terms</Heading>
      <Heading level={5}>Commitments</Heading>
      <p>
        This Agreement will bind and inure to the benefit of each party's
        permitted successors and assigns. Neither party may assign this
        Agreement without the advance written consent of the other party, except
        that either party may assign this Agreement in connection with a merger,
        reorganization, acquisition or other transfer of all or substantially
        all of such party's assets or voting securities. Any attempt to transfer
        or assign this Agreement except as expressly authorized under this
        Section 17.1 will be null and void.
      </p>
      <Heading level={5}> Severability</Heading>
      <p>
        If any provision of this Agreement will be adjudged by any court of
        competent jurisdiction to be unenforceable or invalid, that provision
        will be limited to the minimum extent necessary so that this Agreement
        will otherwise remain in effect.
      </p>
      <Heading level={5}> Governing Law; Dispute Resolution</Heading>
      <p>
        a) Direct Dispute Resolution. In the event of any dispute, claim,
        question, or disagreement arising from or relating to this Agreement,
        whether arising in contract, tort or otherwise, ("Dispute"), the parties
        shall first use their best efforts to resolve the Dispute. If a Dispute
        arises, the complaining party shall provide written notice to the other
        party in a document specifically entitled "Initial Notice of Dispute,"
        specifically setting forth the precise nature of the dispute ("Initial
        Notice of Dispute"). If an Initial Notice of Dispute is being sent to
        Customerly it must be emailed to info@customerly.io and sent via mail
        to:
      </p>
      <p>
        After receiving of the Initial Notice of Dispute, both parties
        undertakes to consult and negotiate with each other in good faith and,
        recognizing their mutual interest, attempt to reach a just and equitable
        solution of the Dispute that is satisfactory to both parties ("Direct
        Dispute Resolution"). If the parties are unable to reach a resolution of
        the Dispute through Direct Dispute Resolution within thirty (30) days of
        the receipt of the Initial Notice of Dispute, then the Dispute shall
        subsequently be resolved by arbitration as set forth below.
      </p>
      <Heading level={5}>Law and Jurisdiction</Heading>
      <p>
        Given that the Parties have agreed to adopt a dispute resolution, as
        provided above, for other claims which is not subject to ADR according
        to law, the Parties agrees to submit and consent to the Jurisdiction of
        as competent court, and that law shall apply.
      </p>
      <Heading level={5}>
        The Parties agrees to consider this Agreement as Jointly written.
      </Heading>
      <p>
        Both Client and Customerly agree that each part may bring or participate
        in claims against the other only in their respective individual
        capacities and not as a plaintiff or class member in any purported
        class.
      </p>
      <Heading level={5}>Notice</Heading>
      <p>
        Any notice or communication required or permitted under this Agreement
        will be in writing to the parties at the addresses set forth on the
        Order Form or at such other address as may be given in writing by either
        party to the other in accordance with this Section and will be deemed to
        have been received by the addressee (i) if given by hand, immediately
        upon receipt; (ii) if given by overnight courier service, the first
        business day following dispatch or (iii) if given by registered or
        certified mail, postage prepaid and return receipt requested, the second
        business day after such notice is deposited in the mail.
      </p>
      <Heading level={5}>Amendments; Waivers.</Heading>
      <p>
        Except as otherwise provided herein, no supplement, modification, or
        amendment of this Agreement will be binding, unless executed in writing
        by a duly authorized representative of each party to this Agreement. No
        waiver will be implied from conduct or failure to enforce or exercise
        rights under this Agreement, nor will any waiver be effective unless in
        a writing signed by a duly authorized representative on behalf of the
        party claimed to have waived. No provision of any purchase order or
        other business form employed by Client will supersede the terms and
        conditions of this Agreement, and any such document relating to this
        Agreement will be for administrative purposes only and will have no
        legal effect.
      </p>
      <Heading level={5}>Entire Agreement</Heading>
      <p>
        This Agreement is the complete and exclusive statement of the mutual
        understanding of the parties and supersedes and cancels all previous
        written and oral agreements and communications relating to the subject
        matter of this Agreement. Client acknowledges that the Services are
        on-line, subscription-based products, and that in order to provide
        improved Client experience Customerly may make changes to the Services,
        and Customerly will update the applicable Documentation accordingly. The
        support and service level availability terms described in the Support
        Policy and the SLA may be updated from time to time upon reasonable
        notice to Client to reflect process improvements or changing practices
        (but the modifications will not materially decrease Customerly's
        obligations as compared to those reflected in such terms as of the
        Effective Date).
      </p>
      <Heading level={5}> Force Majeure</Heading>
      <p>
        Neither party will be liable to the other for any delay or failure to
        perform any obligation under this Agreement (except for a failure to pay
        fees) if the delay or failure is due to unforeseen events that occur
        after the signing of this Agreement and that are beyond the reasonable
        control of such party, such as a strike, blockade, war, act of
        terrorism, riot, natural disaster, failure or diminishment of power or
        telecommunications or data networks or services, or refusal of a license
        by a government agency.
      </p>
      <Heading level={5}> Subcontractors </Heading>
      <p>
        Customerly may use the services of subcontractors and permit them to
        exercise the rights granted to Customerly in order to provide the
        Services under this Agreement, provided that Customerly remains
        responsible for (i) compliance of any such subcontractor with the terms
        of this Agreement and (ii) for the overall performance of the Services
        as required under this Agreement.
      </p>
      <Heading level={5}>Subpoenas</Heading>
      <p>
        Nothing in this Agreement prevents Customerly from disclosing Client
        Data to the extent required by law, subpoenas, or court orders, but
        Customerly will use commercially reasonable efforts to notify Client
        where permitted to do so.
      </p>
      <Heading level={5}>Independent Contractors.</Heading>
      <p>
        The parties to this Agreement are independent contractors. There is no
        relationship of partnership, joint venture, employment, franchise or
        agency created hereby between the parties. Neither party will have the
        power to bind the other or incur obligations on the other party's behalf
        without the other party's prior written consent
      </p>
      <Heading level={5}> Export Control</Heading>
      <p>
        In its use of the Services, Client undertakes to a full compliance with
        all export and import laws and regulations and other applicable
        jurisdictions. Without limiting the foregoing, (i) Client represents and
        warrants that it is not listed on any list of prohibited or restricted
        parties or located in (or a national of) a country that is subject to
        embargo or that has been designated by any government as a "terrorist
        supporting" country, (ii) Client will not (and will not permit any of
        its users to) access or use the Services in violation of any export
        embargo, prohibition or restriction, and (iii) Client will not submit to
        the Services any information that is controlled under any Traffic in
        Arms Regulations and Laws.
      </p>
      <Heading level={5}> Counterparts</Heading>
      <p>
        This Agreement may be executed in counterparts, each of which will be
        deemed an original and all of which together will be considered one and
        the same agreement.{" "}
      </p>
    </Service>
  );
};
